<script>
import feather from 'feather-icons';
// import ProjectsFilter from './ProjectsFilter.vue';
// import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';

export default {
	components: {
		// ProjectSingle,
		// ProjectsFilter
	},
	data: () => {
		return {
			projects,
			projectsHeading: 'Products & Services',
			selectedCategory: '',
			searchProject: '',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			if (this.selectedCategory) {
				return this.filterProjectsByCategory();
			} else if (this.searchProject) {
				return this.filterProjectsBySearch();
			}
			return this.projects;
		},
	},
	methods: {
		// Filter projects by category
		filterProjectsByCategory() {
			return this.projects.filter((item) => {
				let category =
					item.category.charAt(0).toUpperCase() +
					item.category.slice(1);
				console.log(category);
				return category.includes(this.selectedCategory);
			});
		},
		// Filter projects by title search
		filterProjectsBySearch() {
			let project = new RegExp(this.searchProject, 'i');
			return this.projects.filter((el) => el.title.match(project));
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-10 sm:pt-14">
		<!-- Projects grid title -->
		<div style="padding-top: 200px;"></div>

		<div class="text-center">
			<p
				class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
				style="font-size: 30px;"
			>
				{{ projectsHeading }}
			</p>
		</div>
		<div style="padding-bottom: 10px;"></div>

		<!-- Filter and search projects -->
		<div class="mt-10 sm:mt-10">
			<h3
				class="font-general-regular
					text-center text-secondary-dark
					dark:text-ternary-light
					text-md
					sm:text-xl
					font-normal
					mb-4
				"
			>
<!--				Search projects by title or filter by category-->
			</h3>
		</div>
		<!-- Projects grid -->
		<div
				class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10"
		>
			<!--			<ProjectSingle-->
			<!--				v-for="project in filteredProjects"-->
			<!--				:key="project.id"-->
			<!--				:project="project"-->
			<!--			/>-->
			<div
					onclick=""
					class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
			>
				<div>
					<img
							src="../../assets/images/ivyed-capture.png"
							class="rounded-t-xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
							class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						IVYED Korea - www.ivyed.co.kr
					</p>
					<span
							class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light">미국 상위 1% 명문대 선생님과 1:1 온라인 유학 과외, IVYED - 아이비에드</span>
					<span  class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light">
				<br/>
				<br/>
						<!--				<v-btn elevation="5" color="red"> {{project.title}} 알아보기 </v-btn>-->
			</span>
				</div>

			</div>
			<div
					onclick=""
					class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
					aria-label="Single Project"
			>
				<div>
					<img
							src="../../assets/images/ivyed-global.png"
							class="rounded-t-xl border-none"/>
				</div>
				<div class="text-center px-4 py-6">
					<p
							class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-semibold mb-2"
					>
						IVYED Global - www.ivyed.org
					</p>
					<span
							class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>Online College Prep With Ivy Leaguers
						<br/>
						<br/>
Ace your exams and school courses with Ivy League tutors</span
					>
					<span  class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>
				<br/>
				<br/>
						<!--				<v-btn elevation="5" color="red"> {{project.title}} 알아보기 </v-btn>-->
			</span>
				</div>

			</div>
			<router-link
					to="/GodOfEducation"
					class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
					aria-label="Single Project"
			>
				<div>
					<img
							src="../../assets/images/GodOfEducation.png"
							class="rounded-t-xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
							class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-weight-bold mb-2"
					>
						공부의신
					</p>
					<span
							class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>AI 기반의 언어 학습 도구로, 사용자의 언어 학습 편의를 돕기 위해 고안되었습니다. AI 사전, 번역, 문법, 작문 기능을 이용하실 수 있습니다.
<!--						<br/>-->
<!--						<br/>-->
<!--AI 사전, 번역, 문법, 작문 기능은 모두 채팅 형식으로 이루어져 있어 AI와 이야기를 나누는 것처럼 즐겁게 영어 공부를 할 수 있습니다.-->

</span
					>
					<span  class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>
				<br/>
				<br/>
						<!--				<v-btn elevation="5" color="red"> {{project.title}} 알아보기 </v-btn>-->
			</span>
				</div>


			</router-link>
			<router-link
					to="/creAItest"
					class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
					aria-label="Single Project"
			>
				<div>
					<img
							src="../../assets/images/IOS_6.7_1.jpeg"
							class="rounded-t-xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
							class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-weight-bold mb-2"
					>
						creAItest
					</p>
					<span
							class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>Introducing creAItest, your go-to app for effortlessly generating multiple-choice questions based on existing images. Unleash the power of personalized problems with our user-friendly features.</span
					>
					<span  class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>
				<br/>
				<br/>
						<!--				<v-btn elevation="5" color="red"> {{project.title}} 알아보기 </v-btn>-->
			</span>
				</div>
			</router-link>
			<router-link
					to="/folaio"
					class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
					aria-label="Single Project"
			>
				<div>
					<img
							src="../../assets/images/folaio/1.png"
							class="rounded-t-xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
							class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-weight-bold mb-2"
					>
						Folaio
					</p>
					<span
							class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>Folaio, where you can share and enjoy short videos. Now is your chance to have fun!

Introducing a new short-form video platform where you can share and enjoy short, entertaining videos.</span
					>
					<span  class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>
				<br/>
				<br/>
						<!--				<v-btn elevation="5" color="red"> {{project.title}} 알아보기 </v-btn>-->
			</span>
				</div>
			</router-link>
			<router-link
					to="/synchronize-notes"
					class="rounded-xl shadow-lg hover:shadow-xl cursor-pointer mb-10 sm:mb-0 bg-secondary-light dark:bg-ternary-dark"
					aria-label="Single Project"
			>
				<div>
					<img
							src="../../assets/images/synchronizeNotes/snotes1.png"
							class="rounded-t-xl border-none"
					/>
				</div>
				<div class="text-center px-4 py-6">
					<p
							class="font-general-semibold text-xl text-ternary-dark dark:text-ternary-light font-weight-bold mb-2"
					>
						SynchronizeNotes
					</p>
					<span
							class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>Meet SynchronizeNotes: The Ultimate Solution for Scattered Notes
						<br/>
						<br/>

Struggling with notes spread across Slack, WhatsApp, KakaoTalk, and more? You're not alone. SynchronizeNotes was created to tackle this exact problem. We understood the frustration of managing notes in multiple places, so we built a solution to bring them all together.</span
					>
					<span  class="font-general-medium text-lg text-ternary-dark dark:text-ternary-light"
					>
				<br/>
				<br/>
						<!--				<v-btn elevation="5" color="red"> {{project.title}} 알아보기 </v-btn>-->
			</span>
				</div>
			</router-link>
		</div>
	</section>
</template>

<style scoped></style>
