// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'IVYED Global - www.ivyed.org',
		category: 'Online tutoring for US / UK College Preparation',
		img: require('@/assets/images/ivyed-global.png'),
	},
	{
		id: 2,
		title: 'IVYED Korea - www.ivyed.co.kr',
		// category: 'Mobile Application',
		img: require('@/assets/images/ivyed-capture.png'),
	},
	// {
	// 	id: 3,
	// 	title: '공부의신',
	// 	category: '이 앱은 AI 기반의 언어 학습 도구로, 사용자의 언어 학습 편의를 돕기 위해 고안되었습니다. AI 사전, 번역, 문법, 작문 기능을 이용하실 수 있습니다.',
	// 	img: require('@/assets/images/GodOfEducation.png'),
	// 	link: "/GodOfEducation"
	// },
	// {
	// 	id: 4,
	// 	title: 'Cloud Storage Platform',
	// 	category: 'UI/UX Design',
	// 	img: require('@/assets/images/ui-project-2.jpg'),
	// },
	// {
	// 	id: 5,
	// 	title: 'React Social App',
	// 	category: 'Mobile Application',
	// 	img: require('@/assets/images/mobile-project-1.jpg'),
	// },
	// {
	// 	id: 6,
	// 	title: 'Apple Design System',
	// 	category: 'Web Application',
	// 	img: require('@/assets/images/web-project-1.jpg'),
	// },
];

export default projects;
