//
// // import VueRouter from "vue-router";
// import { createRouter, createWebHistory } from 'vue-router';
// import Home from '../views/Home.vue';
// // import VueMeta from 'vue-meta'
//
//
// const routes = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		component: Home,
// 		meta: {
// 			title: 'To Infinity - Home',
// 		},
// 	},
// 	{
// 		path: '/about',
// 		name: 'About',
// 		// route level code-splitting
// 		// this generates a separate chunk (about.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "about" */ '../views/About.vue'),
// 		meta: {
// 			title: 'To Infinity - About',
// 		},
// 	},
// 	{
// 		path: '/projects',
// 		name: 'Projects',
// 		// route level code-splitting
// 		// this generates a separate chunk (projects.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
// 		meta: {
// 			title: 'To Infinity - Projects',
// 		},
// 	},
// 	{
// 		path: '/projects/single-project',
// 		name: 'Single Project',
// 		// route level code-splitting
// 		// this generates a separate chunk (projects.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(
// 				/* webpackChunkName: "projects" */ '../views/SingleProject.vue'
// 			),
// 		meta: {
// 			title: 'To Infinity - Single Project',
// 		},
// 	},
// 	{
// 		path: '/contact',
// 		name: 'Contact',
// 		// route level code-splitting
// 		// this generates a separate chunk (projects.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "projects" */ '../views/Contact.vue'),
// 		meta: {
// 			title: 'To Infinity - Contact',
// 		},
// 	},
//
// 	{
// 		path: '/recruit',
// 		name: 'Recruit',
// 		// route level code-splitting
// 		// this generates a separate chunk (projects.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "projects" */ '../views/Recruit.vue'),
// 		meta: {
// 			title: 'To Infinity - Recruit',
// 		},
// 	},
// 	{
// 		path: '/internship/marketing',
// 		name: 'MarketingIntern',
// 		// route level code-splitting
// 		// this generates a separate chunk (projects.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "projects" */ '../views/Hiring/MarketingIntern.vue'),
// 		meta: {
// 			title: 'To Infinity',
// 		},
// 	},
// 	{
// 		path: '/jobs/marketing',
// 		name: 'Marketing',
// 		// route level code-splitting
// 		// this generates a separate chunk (projects.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "projects" */ '../views/Hiring/Marketing.vue'),
// 		meta: {
// 			title: 'To Infinity',
// 		},
// 	},
// 	{
// 		path: '/jobs/operationsManager',
// 		name: 'OperationsManager',
// 		// route level code-splitting
// 		// this generates a separate chunk (projects.[hash].js) for this route
// 		// which is lazy-loaded when the route is visited.
// 		component: () =>
// 			import(/* webpackChunkName: "projects" */ '../views/Hiring/OperationsManager.vue'),
// 		meta: {
// 			title: 'To Infinity',
// 		},
// 	},
//     {
//         path: '/GodOfEducation',
//         name: 'GodOfEducation',
//         // route level code-splitting
//         // this generates a separate chunk (projects.[hash].js) for this route
//         // which is lazy-loaded when the route is visited.
//         component: () =>
//             import(/* webpackChunkName: "projects" */ '../views/Products/GodOfEducation.vue'),
//         meta: {
//             title: '공부의신',
//         },
//     },
//
// ];
//
// const router = new createRouter({
// 	// base:,
//
// 	// history: createWebHistory(process.env.BASE_URL),
// 	history: createWebHistory(process.env.BASE_URL),
// 	routes,
// 	// scrollBehavior() {
// 	// 	document.getElementById('app').scrollIntoView();
// 	// mode: 'history',
//
// });
//
// export default router;
//
// /**
//  * Below code will display the component/active page title
//  * Powered by: Nangialai Stoman
//  */
//
// // This callback runs before every route change, including on page load.
// // router.beforeEach((to, from, next) => {
// 	// This goes through the matched routes from last to first, finding the closest route with a title.
// 	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
// 	// `/nested`'s will be chosen.
// 	const nearestWithTitle = to.matched
// 		.slice()
// 		.reverse()
// 		.find((r) => r.meta && r.meta.title);
//
// 	// Find the nearest route element with meta tags.
// 	const nearestWithMeta = to.matched
// 		.slice()
// 		.reverse()
// 		.find((r) => r.meta && r.meta.metaTags);
//
// 	const previousNearestWithMeta = from.matched
// 		.slice()
// 		.reverse()
// 		.find((r) => r.meta && r.meta.metaTags);
//
// 	// If a route with a title was found, set the document (page) title to that value.
// 	if (nearestWithTitle) {
// 		document.title = nearestWithTitle.meta.title;
// 	} else if (previousNearestWithMeta) {
// 		document.title = previousNearestWithMeta.meta.title;
// 	}
//
// 	// Remove any stale meta tags from the document using the key attribute we set below.
// 	Array.from(
// 		document.querySelectorAll('[data-vue-router-controlled]')
// 	).map((el) => el.parentNode.removeChild(el));
//
// 	// Skip rendering meta tags if there are none.
// 	if (!nearestWithMeta) return next();
//
// 	// Turn the meta tag definitions into actual elements in the head.
// 	nearestWithMeta.meta.metaTags
// 		.map((tagDef) => {
// 			const tag = document.createElement('meta');
//
// 			Object.keys(tagDef).forEach((key) => {
// 				tag.setAttribute(key, tagDef[key]);
// 			});
//
// 			// We use this to track which meta tags we create so we don't interfere with other ones.
// 			tag.setAttribute('data-vue-router-controlled', '');
//
// 			return tag;
// 		})
// 		// Add the meta tags to the document head.
// 		.forEach((tag) => document.head.appendChild(tag));
//
// 	next();
// });


// import VueRouter from "vue-router";
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
// import VueMeta from 'vue-meta'


const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: 'To Infinity - Home',
		},
	},
	{
		path: '/about',
		name: 'About',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "about" */ '../views/About.vue'),
		meta: {
			title: 'To Infinity - About',
		},
	},
	{
		path: '/projects',
		name: 'Projects',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
		meta: {
			title: 'To Infinity - Projects',
		},
	},
	{
		path: '/projects/single-project',
		name: 'Single Project',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(
				/* webpackChunkName: "projects" */ '../views/SingleProject.vue'
				),
		meta: {
			title: 'To Infinity - Single Project',
		},
	},
	{
		path: '/contact',
		name: 'Contact',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Contact.vue'),
		meta: {
			title: 'To Infinity - Contact',
		},
	},

	{
		path: '/recruit',
		name: 'Recruit',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Recruit.vue'),
		meta: {
			title: 'To Infinity - Recruit',
		},
	},
	{
		path: '/internship/marketing',
		name: 'MarketingIntern',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Hiring/MarketingIntern.vue'),
		meta: {
			title: 'To Infinity',
		},
	},
	{
		path: '/jobs/marketing',
		name: 'Marketing',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Hiring/Marketing.vue'),
		meta: {
			title: 'To Infinity',
		},
	},
	{
		path: '/jobs/operationsManager',
		name: 'OperationsManager',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Hiring/OperationsManager.vue'),
		meta: {
			title: 'To Infinity',
		},
	},
	{
		path: '/GodOfEducation',
		name: 'GodOfEducation',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Products/GodOfEducation.vue'),
		meta: {
			title: '공부의신',
		},
	},
	{
		path: '/creAItest',
		name: 'creAItest',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Products/creAItest.vue'),
		meta: {
			title: 'creAItest - your ultimate test bank',
		},
	},
	{
		path: '/folaio',
		name: 'folaio',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Products/folaio.vue'),
		meta: {
			title: 'Folaio - Introducing a new short-form video platform where you can share and enjoy short, entertaining videos.',
		},
	},
	{
		path: '/synchronize-notes',
		name: 'synchronizeNotes',
		// route level code-splitting
		// this generates a separate chunk (projects.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import(/* webpackChunkName: "projects" */ '../views/Products/synchronizeNotes.vue'),
		meta: {
			title: 'Meet SynchronizeNotes: The Ultimate Solution for Scattered Notes\n' +
				'\n' +
				'Struggling with notes spread across Slack, WhatsApp, KakaoTalk, and more? You\'re not alone. SynchronizeNotes was created to tackle this exact problem. We understood the frustration of managing notes in multiple places, so we built a solution to bring them all together.',
		},
	},

];

const router = new createRouter({
	// base:,

	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHistory(process.env.BASE_URL),
	routes,
	// scrollBehavior() {
	// 	document.getElementById('app').scrollIntoView();
	// mode: 'history',

});

export default router;

/**
 * Below code will display the component/active page title
 * Powered by: Nangialai Stoman
 */

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(
		document.querySelectorAll('[data-vue-router-controlled]')
	).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement('meta');

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute('data-vue-router-controlled', '');

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});
