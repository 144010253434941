<script>
export default {
	data: () => {
		return {
			copyrightDate: new Date().getFullYear(),
			projectName: 'Vue.js & Tailwind CSS Portfolio',
			author: 'Stoman',
		};
	},
};
</script>

<template>
	<div class=" ">
		<strong>(주) 투인피니티</strong><br/>
		서울특별시 서초구 강남대로97길 26, 5층
		<br/>

		<br/>
		대표: 김주남
		<br/> 사업자번호 : 870-87-02276 <br/>

<!--<br/>-->

		<br/>
		통신판매업신고: 2022-서울 서초-1617
		<br/>
		TEL: 070-8970-5223 <br/>
		EMAIL: admin@toinfinity.co.kr
		<br/>
		<br/>
		<!--                    <br/>-->
		<br/>


<!--		<div-->
<!--			class="font-general-regular text-lg text-ternary-dark dark:text-ternary-light"-->
<!--		>-->
<!--			&copy; 2023.-->
<!--			<a-->
<!--				href="https://github.com/realstoman/vuejs-tailwindcss-portfolio"-->
<!--				target="__blank"-->
<!--				class="font-general-medium hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500"-->
<!--			>-->
<!--				TO INFINITY-->
<!--			</a>-->

<!--&lt;!&ndash;			. Developed by&ndash;&gt;-->
<!--&lt;!&ndash;			<a&ndash;&gt;-->
<!--&lt;!&ndash;				href="https://stoman.me"&ndash;&gt;-->
<!--&lt;!&ndash;				target="__blank"&ndash;&gt;-->
<!--&lt;!&ndash;				class="font-general-medium text-secondary-dark dark:text-secondary-light uppercase hover:underline hover:text-indigo-600 dark:hover:text-indigo-300 duration-500"&ndash;&gt;-->
<!--&lt;!&ndash;				>&ndash;&gt;-->
<!--&lt;!&ndash;				{{ author }}&ndash;&gt;-->
<!--&lt;!&ndash;			</a&ndash;&gt;-->
<!--&lt;!&ndash;			>&ndash;&gt;-->
<!--		</div>-->
	</div>
</template>

<style lang="scss" scoped></style>
